import React, { useState, useEffect } from "react";
import PositionDropdown from "./position_dropdown";
import HeightField from "./height_field";
import WeightField from "./weight_field";
import WeightInput from "./form_fields/weight_input";

const gradYears = [
  "2019",
  "2020",
  "2021",
  "2022",
  "2023",
  "2024",
  "2025",
  "2026",
  "2027",
  "2028",
  "2029",
  "2030",
  "2031",
  "2032",
  "2033",
  "N/A",
];

const shirtSizes = [
  "N/A",
  "Y-Small",
  "Y-Medium",
  "Y-Large",
  "Small",
  "Medium",
  "Large",
  "X-Large",
];

const capitalizeString = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const ProvidenceLaxSportForm = ({
  sport,
  event_type,
  full_position_price_points,
  price_points,
  collect_tee_shirt_size,
  buttonStyles,
  saveValues,
  nextStep,
}) => {
  const [formValues, setFormValues] = useState({
    height: "",
    weight: "",
    position: "Check Price Point",
    gpa: "",
    sat: "",
    act_score: "",
    club_team: "",
    user_notes: "",
    roommate_preference: "",
    peers_attending: "",
    graduation_year: "2022",
    shirt_size: "N/A",
    custom_data: {},
  });

  const setDefaultPosition = (sport) => {
    const defaultPositions = {
      soccer: "Forward",
      basketball: "PG",
      lacrosse: "Check Price Point",
      hockey: "Forward",
      volleyball: "Outside Hitter",
      baseball: "C",
      softball: "C",
      wrestling: "125 lbs",
      tennis: "Singles",
      golf: "Golfer",
      "field-hockey": "Forward",
      swimming: "Freestyle",
      track: "Sprinter",
      gymnastics: "All-Around",
      "soccer-advanced": "CM",
      "multi-sport": "soccer",
      "sports-performance": "other",
      rowing: "Coxswain",
      "water-polo": "Goalkeeper",
      fencing: "Epee",
      rifle: "Smallbore",
      bowling: "Lead",
      "cross-country": "Runner",
      skiing: "Alpine",
      esports: "Top",
      "ultimate-frisbee": "Handler",
      cheerleading: "Base",
      dance: "Lead",
      "martial-arts": "Striker",
      football: "QB"
    };

    return defaultPositions[sport] || "All / Any";
  };



  useEffect(() => {
    handlePositionChange(setDefaultPosition(sport))
  }, [])

  const generateOptions = (values) => {
    return values.map((value) => (
      <option value={value} key={value}>
        {value}
      </option>
    ));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  function handleInputChange(e) {
    const { name, value } = e.target;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));
  }

  function handlePositionChange(positionValue) {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      position: positionValue,
    }));
  }

  function handleHeightChange(newHeight) {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      height: newHeight,
    }));
  }

  function handleWeightChange(newWeight) {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      weight: newWeight,
    }));
  }

  function saveAndContinue(e) {
    e.preventDefault();
    const data = {
      height: formValues.height,
      weight: formValues.weight + "lbs",
      position: formValues.position,
      club_team: formValues.club_team,
      gpa: formValues.gpa,
      sat: formValues.sat,
      act_score: formValues.act_score,
      graduation_year: formValues.graduation_year,
      roommate_preference: formValues.roommate_preference,
      peers_attending: formValues.peers_attending,
      shirt_size: formValues.shirt_size,
      user_notes: formValues.user_notes,
    };
    saveValues(data);
    nextStep();
    
  }

  function isValid() {
    return (
      formValues.height &&
      formValues.weight &&
      formValues.club_team
    );
  }

  function validateEmail(email) {
    const emailCheck =
      /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
    return emailCheck.test(email);
  }

  const validateLength = (field, requiredLength) => {
    if (field && field.length > requiredLength) {
      return true;
    }
  };

  const addRoommatePreferenceField = () => {
    if (event_type === "traditional") {
      return (
        <div className="half">
          <label>Roommate Preferences:</label>
          <span className="error-span"></span>
          <textarea
            rows="5"
            name="roommate_preference"
            value={formValues.roommate_preference}
            onChange={handleChange}
            placeholder="Please list any roommate preferences..."
          ></textarea>
        </div>
      );
    }
  };

  const addNotesField = () => {
    if (event_type === "traditional") {
      return (
        <div className="half">
          <label>Notes:</label>
          <span className="error-span"></span>
          <textarea
            rows="5"
            name="user_notes"
            value={formValues.user_notes}
            onChange={handleChange}
            placeholder="Anything else you'd like for us to know"
          ></textarea>
        </div>
      );
    }
  };

  const addIdCampFields = () => {
    if (event_type != "day-camp") {
      return (
        <div className="camper-info-row">
          <div className="half">
            <label>GPA:</label>
            <span className="error-span"></span>
            <input
              type="text"
              className="gpa"
              placeholder="Enter GPA"
              name="gpa"
              value={formValues.gpa}
              data-error-message={
                "GPA field must not be blank - N/A is a valid response."
              }
              data-min-length={"2"}
              onChange={handleChange}
              onBlur={checkInput}
            />
          </div>
          <div className="half">
            <label>SAT Score</label>
            <span className="error-span"></span>
            <input
              type="text"
              className="sat"
              placeholder="Enter SAT Score"
              name="sat"
              required={true}
              value={formValues.sat}
              data-error-message={
                "SAT field must not be blank - N/A is a valid response."
              }
              data-min-length={"2"}
              onChange={handleChange}
              onBlur={checkInput}
            />
          </div>
          <div className="half">
            <label>ACT Score:</label>
            <span className="error-span"></span>
            <input
              type="text"
              className="sat"
              placeholder="Enter ACT Score"
              name="act_score"
              required={true}
              value={formValues.act_score}
              data-error-message={
                "ACT field must not be blank - N/A is a valid response."
              }
              data-min-length={"1"}
              onChange={handleChange}
              onBlur={checkInput}
            />
          </div>
          <div className="half">
            <p>Graduation Years</p>
            <select
              onChange={handleChange}
              name={"graduation_year"}
              className={"grad-year field"}
              data-name={"graduation-year"}
            >
              {generateOptions(gradYears)}
            </select>
          </div>
          <div className="half">
            <p>Notes</p>
            <span className="error-span"></span>
            <textarea
              rows={{ 5: 5 }}
              name="user_notes"
              value={formValues.user_notes}
              onChange={handleChange}
              placeholder="Anything else you'd like for us to know"
            ></textarea>
          </div>
        </div>
      );
    }
  };

  const addShirtField = () => {
    if (collect_tee_shirt_size) {
      return (
        <div className="camper-info-row">
          <div className="half">
            <p>Shirt Size (if applicable): *</p>
            <span className="error-span"></span>
            <select
              onChange={handleChange}
              name={"shirt_size"}
              className={"shirt-size field"}
              data-name={"shirt_size"}
            >
              {generateOptions(shirtSizes)}
            </select>
          </div>
        </div>
      );
    }
  };

  const RoommatePreferenceField = () => {
    if (props.event_type === "traditional") {
      return (
        <div className="half">
          <label>Roommate Preferences:</label>
          <span className="error-span"></span>
          <textarea
            rows={5}
            placeholder="Please list any roommate preferences..."
            name="roommate_preference"
            ref="roommate_preference"
            value={state.roommate_preference}
            onChange={handleChange}
          />
        </div>
      );
    }
    return null;
  };

  // <div className="camper-info-row">
  //   <RoommatePreferenceField />
  // </div>
  // <IdCampFields />
  // <ShirtField

  const IdCampFields = () => {
    if (props.event_type !== "day-camp") {
      return (
        <>
          <div className="camper-info-row">
            <div className="half">
              <label className="">GPA:</label>
              <span className="error-span"></span>
              <input
                type="text"
                className="gpa"
                placeholder="Enter GPA"
                name="gpa"
                ref="gpa"
                required
                value={state.gpa}
                data-error-message="GPA field must not be blank - N/A is a valid response."
                data-min-length="2"
                onChange={handleChange}
                onBlur={checkInput}
              />
            </div>
            <div className="half">
              <label className="">SAT:</label>
              <span className="error-span"></span>
              <input
                type="text"
                className="sat"
                placeholder="Enter SAT"
                name="sat"
                ref="sat"
                required
                value={state.sat}
                data-error-message="SAT field must not be blank - N/A is a valid response."
                data-min-length="2"
                onChange={handleChange}
                onBlur={checkInput}
              />
            </div>
          </div>
          <div className="half">
            <label>Graduation Year</label>
            <select
              onChange={handleChange}
              name="graduation_year"
              className="grad-year field"
              ref="grad-year"
              data-name="graduation-year"
            >
              {["2019", "2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030", "N/A"].map((year) => (
                <option value={year} key={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
        </>
      );
    }
    return null;
  };

  const ShirtField = () => {
    if (props.collect_tee_shirt_size) {
      return (
        <div className="camper-info-row">
          <div className="half">
            <span className="error-span"></span>
            <label>Shirt Size (if applicable): *</label>
            <select
              className="shirt-size field"
              name="shirt_size"
              ref="shirt_size"
              onChange={handleChange}
            >
              {Array.from(shirtSizes()).map((shirt_size) => (
                <option value={shirt_size} key={shirt_size}>
                  {shirt_size}
                </option>
              ))}
            </select>
          </div>
        </div>
      );
    }
    return null;
  };


  const checkInput = (e) => {
    const itemClass = e.target.className;
    const item = e.target;
    const val = e.target.value;
    const errorSpan = item.parentElement.querySelector(".error-span");
    return showUserFieldStatus(
      item,
      errorSpan,
      val.toString().length,
      item.dataset.minLength
    );
  };

  const showUserFieldStatus = (item, errorSpan, fieldValue, minLength) => {
    if (fieldValue <= minLength) {
      item.classList.add("field-error");
      const errorMessage = item.dataset.errorMessage.replace(
        "the specified number of",
        item.dataset.minLength.toString()
      );
      return $(errorSpan).html(errorMessage);
    } else {
      item.classList.remove("field-error");
      return $(errorSpan).html("");
    }
  };

    return (
      <form className={"new-step-form"}>
        <h1 className={"form-heading"}>
          {"Step 3. " +
            capitalizeString(sport) +
            " Information"}
        </h1>
        <div className={"camper-info-row"}>
          <div className={"half"}>
            <span className={"error-span"}></span>
            <b><p>{"Position selected at final page"}</p></b>
          </div>
          <div className={"half"}>
            <span className={"error-span"}></span>
            <label>{"Club Team: *"}</label>
            <input
              type={"text"}
              className={"club-team full-field"}
              placeholder={"Club Team or/and High School"}
              name={"club_team"}
              data-error-message={
                "Club Team must be at least one character - N/A is a valid response."
              }
              data-min-length={"1"}
              value={formValues.club_team}
              onChange={handleChange}
              onBlur={checkInput}
            />
          </div>
        </div>
        <div className={"camper-info-row"}>
          <span className={"error-span"}></span>
          <p>{"Height: *"}</p>
          <HeightField
            name={"height"}
            feet={3}
            inches={0}
            value={formValues.height}
            onChange={handleHeightChange}
          />
        </div>
        <div className={"camper-info-row"}>
          <span className={"error-span"}></span>
          <p>{"Weight: *"}</p>
          <WeightField
            name={"weight"}
            value={formValues.weight}
            onChange={handleWeightChange}
          />
        </div>
        <div className={"camper-info-row"}>
          <div className={"half"}>
            <span className={"error-span"}></span>
            <textarea
              rows={{ 5: 5 }}
              className={"allergies full-field"}
              placeholder={"Please list any peers attending...."}
              name={"peers_attending"}
              onChange={handleChange}
              onBlur={checkInput}
              value={formValues.peers_attending}
            ></textarea>
          </div>
          {addRoommatePreferenceField()}
        </div>
        <div className={"camper-info-row"}>{addIdCampFields()}</div>
        <div className={"camper-info-row"}>{addShirtField()}</div>
        <div className={"camper-info-row"}>
          <button
            type={"submit"}
            className={"button button-primary create"}
            disabled={!isValid()}
            style={buttonStyles}
            onClick={saveAndContinue}
          >
            {"Next Step"}
          </button>
        </div>
      </form>
    );
}

export default ProvidenceLaxSportForm